<template>
  <div>
    <div class="text-center mt-5 mb-4">
      <div class="toggle-planos">
        <span>
          <div
            @click.prevent="btnPlanoMensal()"
            type="button"
            class="btn btn-planos-periodo"
            :class="activeMensal"
            style="padding:6px"
          >
            <div
              class="btn-font"
              :style="
                activeMensal === 'btn-figma'
                  ? 'color: #ffffff'
                  : 'color: #5b5b5b;'
              "
            >
              Mensal
            </div>
            <div></div>
          </div>
        </span>
        <span>
          <div
            @click.prevent="btnPlanoSemestral()"
            type="button"
            class="btn btn-planos-periodo"
            :class="activeSemestral"
          >
            <div
              class="btn-font"
              :style="
                activeSemestral === 'btn-figma'
                  ? 'color: #ffffff'
                  : 'color: #5b5b5b;'
              "
            >
              Semestral
            </div>
            <div class="btn-font-desconto-active">
              <span class="btn-font-desconto-text-active">
                <span
                  style="font-size: 6px;
    margin: 7px;"
                >
                  5% OFF</span
                >
              </span>
            </div>
          </div></span
        >
        <span>
          <div
            @click.prevent="btnPlanoAnual()"
            type="button"
            class="btn btn-planos-periodo"
            :class="activeAnual"
          >
            <div
              class="btn-font"
              :style="
                activeAnual === 'btn-figma'
                  ? 'color: #ffffff'
                  : 'color: #5b5b5b;'
              "
            >
              Anual
            </div>
            <div class="btn-font-desconto-active">
              <span class="btn-font-desconto-text-active">
                <span style="font-size: 6px;  margin: 7px;">
                  10% OFF
                </span>
              </span>
            </div>
          </div></span
        >
      </div>
    </div>
    <div class="text-center">
      <b-card class="card-principal-planos">
        <div class="card-principal-planos-urbano">
          <div class="card-principal-planos-titulo-ativo">
            Bike Urbano
          </div>
           <hr class="hr-border ajuste" />
          <span class="card-principal-planos-preco"> 16,51</span>
          <span class="card-principal-planos-tipo">/mês</span>

          <div class="card-principal-planos-orientacao">
            99,11 semestralmente
          </div>

          <div class="tipos-coberturas">
            <span
              ><b-img src="./img/check-sucesso-planos.svg" alt=""></b-img
            ></span>
            <span class="cobertura ativa">Roubo e furto qualificado</span><br />

            <span
              ><b-img src="./img/check-sucesso-planos.svg" alt=""></b-img
            ></span>
            <span class="cobertura ativa">Assistência 24h</span> <br />

            <span
              ><b-img src="./img/check-sucesso-planos.svg" alt=""></b-img
            ></span>
            <span class="cobertura ativa">Transporte e reboque</span><br />
            <span
              ><b-img src="./img/check-sucesso-planos-error.svg" alt=""></b-img
            ></span>
            <span class="cobertura inativa">Quebra por acidente</span><br />

            <span
              ><b-img src="./img/check-sucesso-planos-error.svg" alt=""></b-img
            ></span>
            <span class="cobertura inativa">Incêndio</span><br />
            <span
              ><b-img src="./img/check-sucesso-planos-error.svg" alt=""></b-img
            ></span>
            <span class="cobertura inativa">Queda de raio</span><br />
            <span
              ><b-img src="./img/check-sucesso-planos-error.svg" alt=""></b-img
            ></span>
            <span class="cobertura inativa">Explosão</span><br />
            <span
              ><b-img src="./img/check-sucesso-planos-error.svg" alt=""></b-img
            ></span>
            <span class="cobertura inativa">Colisão</span><br />

            <span
              ><b-img src="./img/check-sucesso-planos-error.svg" alt=""></b-img
            ></span>
            <span class="cobertura inativa">Danos decorrente de transporte</span
            ><br />
            <span
              ><b-img src="./img/check-sucesso-planos-error.svg" alt=""></b-img
            ></span>
            <span class="cobertura inativa">Danos a terceiros</span><br />
            <span
              ><b-img src="./img/check-sucesso-planos-error.svg" alt=""></b-img
            ></span>
            <span class="cobertura inativa" 
              >Extensão para transporte internacional</span
            >
            
          </div>
          <div class="tipos-coberturas-orientacao">
            MAIS ECONÔMICO
          </div>
          <hr class="hr-border ajuste" />
          <div class="card-principal-planos-titulo-inferior">
            CONTRATAR
          </div>
        </div>

        <div class="card-principal-planos-ativo">
          <div class="card-principal-planos-ativo-recomendado-superior">
            <div class="card-principal-planos-ativo-recomendado">
              Recomendado
            </div>
          </div>
          <div class="card-principal-planos-titulo-ativo nao">
            Bike Ativo
          </div>
          <hr class="hr-border ajuste"/>
          <span class="card-principal-planos-preco"> 17,89</span>
          <span class="card-principal-planos-tipo">/mês</span>

          <div class="card-principal-planos-orientacao">
            107,38 semestralmente
          </div>
          <div class="tipos-coberturas">
            <span
              ><b-img src="./img/check-sucesso-planos.svg" alt=""></b-img
            ></span>
            <span class="cobertura ativa">Roubo e furto qualificado</span><br />

            <span
              ><b-img src="./img/check-sucesso-planos.svg" alt=""></b-img
            ></span>
            <span class="cobertura ativa">Assistência 24h</span> <br />

            <span
              ><b-img src="./img/check-sucesso-planos.svg" alt=""></b-img
            ></span>
            <span class="cobertura ativa">Transporte e reboque</span><br />
            <span
              ><b-img src="./img/check-sucesso-planos.svg" alt=""></b-img
            ></span>
            <span class="cobertura ativa">Quebra por acidente</span><br />

            <span
              ><b-img src="./img/check-sucesso-planos.svg" alt=""></b-img
            ></span>
            <span class="cobertura ativa">Incêndio</span><br />
            <span
              ><b-img src="./img/check-sucesso-planos.svg" alt=""></b-img
            ></span>
            <span class="cobertura ativa">Queda de raio</span><br />
            <span
              ><b-img src="./img/check-sucesso-planos.svg" alt=""></b-img
            ></span>
            <span class="cobertura ativa">Explosão</span><br />
            <span
              ><b-img src="./img/check-sucesso-planos.svg" alt=""></b-img
            ></span>
            <span class="cobertura ativa">Colisão</span><br />

            <span
              ><b-img src="./img/check-sucesso-planos.svg" alt=""></b-img
            ></span>
            <span class="cobertura ativa">Danos decorrente de transporte</span
            ><br />
            <span
              ><b-img src="./img/check-sucesso-planos-error.svg" alt=""></b-img
            ></span>
            <span class="cobertura inativa">Danos a terceiros</span><br />
            <span
              ><b-img src="./img/check-sucesso-planos-error.svg" alt=""></b-img
            ></span>
            <span class="cobertura inativa"
              >Extensão para transporte internacional</span
            >
          </div>
          <div class="tipos-coberturas-orientacao ativo">
            MELHOR CUSTO BENEFÍCIO
          </div>
          <div class="card-principal-planos-ativo-recomendado-inferior">
            <div class="card-principal-planos-ativo-recomendado-inferior-c">
              CONTRATAR
            </div>
          </div>
        </div>

        <div class="card-principal-planos-performance">
          <div class="card-principal-planos-titulo-ativo">
            Bike Performance
          </div>
          <hr class="hr-border ajuste" />
          <span class="card-principal-planos-preco"> 20,24</span>
          <span class="card-principal-planos-tipo">/mês</span>

          <div class="card-principal-planos-orientacao">
            141,36 semestralmente
          </div>
          <div class="tipos-coberturas">
            <span
              ><b-img src="./img/check-sucesso-planos.svg" alt=""></b-img
            ></span>
            <span class="cobertura ativa">Roubo e furto qualificado</span><br />

            <span
              ><b-img src="./img/check-sucesso-planos.svg" alt=""></b-img
            ></span>
            <span class="cobertura ativa">Assistência 24h</span> <br />

            <span
              ><b-img src="./img/check-sucesso-planos.svg" alt=""></b-img
            ></span>
            <span class="cobertura ativa">Transporte e reboque</span><br />
            <span
              ><b-img src="./img/check-sucesso-planos.svg" alt=""></b-img
            ></span>
            <span class="cobertura ativa">Quebra por acidente</span><br />

            <span
              ><b-img src="./img/check-sucesso-planos.svg" alt=""></b-img
            ></span>
            <span class="cobertura ativa">Incêndio</span><br />
            <span
              ><b-img src="./img/check-sucesso-planos.svg" alt=""></b-img
            ></span>
            <span class="cobertura ativa">Queda de raio</span><br />
            <span
              ><b-img src="./img/check-sucesso-planos.svg" alt=""></b-img
            ></span>
            <span class="cobertura ativa">Explosão</span><br />
            <span
              ><b-img src="./img/check-sucesso-planos.svg" alt=""></b-img
            ></span>
            <span class="cobertura ativa">Colisão</span><br />

            <span
              ><b-img src="./img/check-sucesso-planos.svg" alt=""></b-img
            ></span>
            <span class="cobertura ativa">Danos decorrente de transporte</span
            ><br />
            <span
              ><b-img src="./img/check-sucesso-planos.svg" alt=""></b-img
            ></span>
            <span class="cobertura ativa">Danos a terceiros</span><br />
            <span
              ><b-img src="./img/check-sucesso-planos.svg" alt=""></b-img
            ></span>
            <span class="cobertura ativa" 
              >Extensão para transporte internacional</span
            >
          </div>
          <div class="tipos-coberturas-orientacao">
            MAIS ECONÔMICO
          </div>
          <hr class="hr-border ajuste" />
          <div>
            <div class="card-principal-planos-titulo-inferior">
              CONTRATAR
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
import config from "../config";

export default {
  data() {
    return {
      activeAnual: "btn-figma",
      activeMensal: "",
      activeSemestral: "",
      planoAnual: true,
      planoSemestral: false,
    };
  },
  methods: {
    btnPlanoAnual() {
      this.activeAnual = "btn-figma";
      this.activeMensal = "";
      this.activeSemestral = "";
      this.planoAnual = true;
      this.planoSemestral = false;
      this.idTipoAssinatura = config.ID_TIPO_ASSINATURA_ANUAL;
      return;
    },
    btnPlanoSemestral() {
      this.activeAnual = "";
      this.activeMensal = "";
      this.activeSemestral = "btn-figma";
      this.planoSemestral = true;
      this.planoAnual = false;

      this.idTipoAssinatura = config.ID_TIPO_ASSINATURA_SEMESTRAL;
      return;
    },

    btnPlanoMensal() {
      this.activeAnual = "";
      this.activeSemestral = "";
      this.activeMensal = "btn-figma";
      this.planoAnual = false;
      this.planoSemestral = false;
      this.idTipoAssinatura = config.ID_TIPO_ASSINATURA_MENSAL;
      return;
    },
  },
};
</script>

<style scoped>
.card-body {
  display: flex;
  padding: 0rem;
}
.btn-font-desconto-text-active {
  background: #b1fcb2;
  border-radius: 15px;
  font-size: 7px;
  line-height: 9px;
  text-align: center;
  color: #444444;
}
.btn-font-desconto-active {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 1px;
  text-align: center;
  color: #ffffff;
}
.btn-font-active {
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
}
.btn-font {
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
}
.btn-figma {
  background: #22ad24;
  border-radius: 15px;
}
.toggle-planos {
  background-color: #e7e7e7;
  border-radius: 100px;
  padding: 3px;
  display: inline-flex;
}
.btn-planos-periodo {
  border-radius: 100px;
  padding: 2px;
  font-size: 12px;
  width: 70px;
}
/* ========================PLANOS=============================== */
.card-principal-planos {
  width: 602px;
  left: 265px;
  top: 156px;
  background: #ffffff;
  border: 2px solid #d8d8d8;
  border-radius: 19px;
  box-sizing: border-box;
  font-family: "Quicksand";
  font-style: normal;
}
.card-principal-planos-urbano {
  box-sizing: border-box;
  width: 210px;
  height: 449px;
  left: 462px;
  top: 130px;
  background: #ffffff;
  border-radius: 19px;
}
.hr-border {
  border: 1px solid #e1e1e1;
}

.hr-border.ajuste {
  margin-top: 0px;
  margin-bottom: 0px;
}
.card-principal-planos-ativo {
  box-sizing: border-box;
  width: 210px;
  left: 462px;
  top: 130px;
  background: #f8f8f8;
  border-bottom: 2px solid #22ad24;
    border-left: 2px solid #22ad24;
    border-right: 2px solid #22ad24;
}
.card-principal-planos-ativo-recomendado-superior {
    box-sizing: border-box;
    width: 210px;
    height: 28px;
    left: 462px;
    top: 130px;
    float: right;
    margin-right: -2px;
    margin-top: -28px;
    background: #22ad24;
    border: 4px solid #22ad24;
    border-radius: 19px 19px 0px 0px;
}

.card-principal-planos-ativo-recomendado {
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  color: #ffffff;
  margin-top: 3px;
}
.card-principal-planos-performance {
  box-sizing: border-box;
  width: 210px;
  left: 462px;
  top: 130px;
  background: #ffffff;
  border-radius: 19px;
}
.card-principal-planos-titulo-ativo {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: #168b18;
  margin-top: 10px;
 margin-bottom: 10px;
}
.card-principal-planos-titulo-ativo.nao{
color:#4E4E4E;
;
}
.card-principal-planos-preco {
  font-family: "Quicksand";
    font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  text-align: center;

  color: #168b18;
}
.card-principal-planos-tipo {
  font-family: "Myriad Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  color: #168b18;
}
.card-principal-planos-orientacao {
  font-family: "Myriad Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #737373;
}
.cobertura {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  margin-left: 5px;
  color: #4e4e4e;
}
.cobertura.ativa {
  color: #4e4e4e;
}
.cobertura.inativa {
  color: #b4b4b4;
}
.tipos-coberturas {
  text-align: left;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 11px;
}
.tipos-coberturas-orientacao {
  font-family: "Myriad Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  color: #737373;
}
.tipos-coberturas-orientacao.ativo {
  font-size: 10px;
  margin-top: 22px;
}

.card-principal-planos-ativo-recomendado-inferior {
  box-sizing: border-box;
  width: 211px;
  height: 0px;
  left: 462px;
  top: 130px;
  float: right;
  margin-right: -2px;
}
.card-principal-planos-ativo-recomendado-inferior-c {
  background: #22ad24;
  margin-top: 02px;
  padding: 6px;
  border-radius: 0px 0px 19px 19px;
  color: #f8f8f8;
  font-weight: 700;cursor: pointer;
}
.card-principal-planos-titulo-inferior {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: #168b18;
  cursor: pointer;
  margin-top: 5px;
}
</style>
